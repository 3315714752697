<template>
  <div>
    <v-container grid-list-lg>
      <v-row dense>
        <v-col cols="2">
          <v-avatar size="100" class="ma-3">
            <img
              v-if="merchant_detail.logo"
              :src="`https://s3-ap-southeast-1.amazonaws.com/assets.getorders/${merchant_detail.logo}`"
              :alt="merchant_detail.name.charAt(0)"
            />
            <span v-else>{{ merchant_detail.name.charAt(0) }}</span>
          </v-avatar>
        </v-col>
        <v-col cols="3">
          <div class="flex flex-column">
            <v-btn color="info" class="mt-5 mb-2">Upload</v-btn>
            <v-btn class="mb-2">Remove</v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Business Name"
            outlined
            color="info"
            :value="merchant_detail.name"
            @input="updateInput($event, 'name')"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Phone Number"
            outlined
            color="info"
            :value="merchant_detail.phone"
            @input="updateInput($event, 'phone')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-date-picker
            v-model="merchant_detail.expired_at"
            :landscape="true"
            color="info"
          ></v-date-picker>
          <v-text-field
            label="Expired At"
            outlined
            color="info"
            :value="returnDate(merchant_detail.expired_at)"
            @input="updateInput($event, 'expired_at')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <h1 class="blue-grey--text nunito lighten-3"><b>Pickup location</b></h1>
    <v-divider></v-divider>
    <v-container grid-list-lg>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            label="Address 1"
            outlined
            color="info"
            :value="merchant_detail.address"
            @input="updateInput($event, 'address')"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Address 2"
            outlined
            color="info"
            :value="merchant_detail.address2"
            @input="updateInput($event, 'address2')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            label="Postcode"
            outlined
            color="info"
            :value="merchant_detail.postcode"
            @input="updateInput($event, 'postcode')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <h1 v-if="merchant_detail.v2" class="blue-grey--text nunito lighten-3"><b>Billplz Bank Detail</b></h1>
    <v-divider v-if="merchant_detail.v2"></v-divider>
    <v-container grid-list-lg v-if="merchant_detail.v2">
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            label="Bank Account Number"
            outlined
            color="info"
            :value="merchant_detail.bank_acc_num"
            @input="updateInput($event, 'bank_acc_num')"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="IC/SSM Number"
            outlined
            color="info"
            :value="merchant_detail.id_num"
            @input="updateInput($event, 'id_num')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            label="Bank"
            outlined
            color="info"
            :value="merchant_detail.bank_code"
            @input="updateInput($event, 'bank_code')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <div class="flex justify-end">
      <v-btn color="success" @click="save">Save</v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  name: "detail",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      merchant_detail: "getImpersonate",
    }),
  },

  methods: {
    updateInput(e, index) {
      this.merchant_detail[index] = e;
      this.$store.commit("updateMerchantSettings", this.merchant_detail);
    },

    returnDate(i) {
      return dayjs(i).format("ddd D/M/YY");
    },

    save() {
      this.$store.dispatch("startLoad");
      this.$store.dispatch("saveMerchant");
    },
  },
};
</script>
